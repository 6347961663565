.reviewDiv{
    box-shadow: 0.2px 0.2px 2px;
    display: inline-block;
    width: 75%;
    margin: 10px auto;
    padding-bottom: 2.5px;
}
@media only screen and (min-width: 1425px){
    .reviewLeft{
        display: block;
        float: left;
        width: 46%;
        margin: 15px 5px 10px 40px;
    }
    .bookReviewInfoImg{
        width: 40%;
    }
    .reviewRight{
        display: block;
        float: right;
        margin: 15px 40px 10px 10px;
        width: 45%;
    }
}
@media only screen and (min-width: 832px) and (max-width: 1424px){
    .reviewLeft{
        display: block;
        float: left;
        width: 46%;
        margin: 15px 5px 10px 20px;
    }
    .bookReviewInfoImg{
        width: 80%;
    }
    .reviewRight{
        display: block;
        float: right;
        margin: 15px 20px 10px 10px;
        width: 45%;
    }
}
@media only screen and (max-width: 831px){
    .reviewLeft{
        display: block;
        float: left;
        width: 90%;
        margin: 15px 5px 10px 5px;
    }
    .bookReviewInfoImg{
        width: 70%;
        margin: 0 auto;
    }
    .reviewRight{
        display: block;
        float: right;
        margin: 15px 20px 10px 10px;
        width: 90%;
    }
}
.bottom{
    display: inline-block;
    width: 75%;
    margin: 0 auto;
}
.contentDiv {
    text-align: justify;
}
.ratingDiv{
    margin: -10px;
}
.reviewDate, .reviewRelease, .reviewPublisher{
    margin: 2px auto;
}
.reviewLink{
    width: 25%;   
}
