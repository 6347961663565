.newsDiv {
    box-shadow: 0.2px 0.2px 2px;
    width: 75%;
    margin: 10px auto;
}

.infoImg {
    max-width: 100%;
}

.leftContentDiv {
    text-align: center;
    width: 45%;
    padding: 10px;
    margin-left: 15px;
    margin-right: 35px;
    display: inline-block;
    vertical-align: top;
}

.rightContentDiv {
    width: 44%;
    display: inline-block;
    margin-right: 25px;
    vertical-align: top;
}
@media only screen and (max-width: 1168px){
    .leftContentDiv, .rightContentDiv{
        display: block;
        margin: 0 auto;
    }
}
@media only screen and (min-width: 900px) {
    .infoImg {
        margin: 10px auto 2.5px auto;
        width: 50%;
    }
}

@media only screen and (max-width: 899px) {
    .infoImg {
        margin: 10px auto 2.5px auto;
        width: 90%;
    }
}

.contentContainer {
    width: 100%;
    margin: 0 auto;
    text-align: justify;
}

.buttonsDiv, .infoDiv {
    padding: 0 0 2.5px 0;
}
