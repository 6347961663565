.aboutDiv{
    box-shadow: 0.2px 0.2px 2px;
    display: inline-block;
    width: 75%;
    margin: 10px auto;
}
@media only screen and (min-width: 835px){
    .aboutImage{
        width: 15%;
        margin-right: 25px;
    }
    .leftAboutCol{
        float: left;
        padding: 0 15px 2.5px 15px;
    }
}
@media only screen and (max-width: 834px){
    .aboutImage{
        width: 50%;
    }
    .leftAboutCol{
        float: none;
        padding: 0 15px 2.5px 15px;
    }
}
.aboutLongBio{
    text-align: justify;
    padding: 0 15px;
}
.aboutMgmtBtn{
    display: inline-block;
    margin-bottom: 15px;
}