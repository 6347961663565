@media only screen and (min-width: 1503px){
    .characterDiv{
        width: 90%;
        margin: 10px auto;
    }
    .characterInfoDiv{
        display: inline-block;
        width: 75%;
        margin-right: 15px;
    }
    .rightMoreCol{
        display: inline-block;
        width: 15%;
        vertical-align: bottom;
    }
    .characterImg{
        width: 100%;
    }
    .mgmtBtn{
        display: block;
        padding: 5px 10px;
    }
}
@media only screen and (max-width: 1502px){
    .characterDiv{
        width: 100%;
        margin: 10px;
    }
    .characterInfoDiv{
        display: inline-block;
        width: 90%;
    }
    .rightMoreCol{
        display: inline-block;
        width: 50%;
    }
    .characterImg{
        width: 50%;
    }
    .mgmtBtn{
        padding: 5px;
    }
}

.infoMgmtDiv{
    width: 100%;
}
.characterImgDiv{
    width: 100%;
    margin-bottom: 15px;
}
.mgmtBtn{
    margin: 0 2.5px;
}
.characterName, .characterRace, .characterBio{
    text-align: justify;
}