.wordDiv{
    width: 100%;
    margin: 10px auto;
}

@media only screen and (min-width: 1219px){
    .wordInfoDiv{
        width: 75%;
        text-align: left;
        vertical-align: bottom;
        
    }
    .wordTitle, .wordPronunciation, .wordDefinition, .wordAudio{
        display: inline;
        margin: 0 15px 0 0;
        text-align: left;
    }
    .wordMgmtBtn{
        float: right;
        margin: 0 10px;
    }
    .wordInfoMgmtDiv{
        width: 25%;
        vertical-align: bottom;
        float: right;
        margin: 0 0 15px 0;
    }
}
@media only screen and (max-width: 1218px){
    .wordInfoDiv{
        width: 100%;
        text-align: left;
        vertical-align: bottom;
        
    }
   .wordTitle, .wordPronunciation, .wordDefinition, .wordAudio{
        display: block;
        margin: 0 15px;
        text-align: left;
    }
    .wordMgmtBtn{
        width: 100%;
        display: block;
        padding: 5px;
    }
    .wordInfoMgmtDiv{
        width: 100%;
        vertical-align: bottom;
        float: right;
        margin: 0 0 15px 0;
    }
}

