.carousel {
    position: relative;
    width: 100%;
    overflow: hidden;
    text-align: center;
}

.carousel-inner {
    display: flex;
    transition: transform 10s ease-in-out;
}

.carousel-item {
    flex: 0 0 100%;
    max-width: 100%;
    display: none;
}

.carousel-item.active {
    display: block;
}

.carousel-control {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    font-size: 2rem;
    cursor: pointer;
    z-index: 1;
    padding: 10px;
}

.carousel-control.prev {
    left: 10px;
}

.carousel-control.next {
    right: 10px;
}
