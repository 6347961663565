.emailListSignup{
    width: 55%;
    border: 1px solid black;
    background-image: url("https://by3302files.storage.live.com/y4mkfExkNv0_3gB7BRCMdaL6p9M5zjYOKBj4YSYU8A4tX5wsZhzfqHMGZP-2sH44JyEGPXbAGRi42j7cL-OQ05Qil2a7bC-5Kxpdcg1waMD0w_pIgmCCGiuQxurVvKwnMkPhSFrreHl1z8v9HHFb2UP63L-w-im-8b8Bq8KetStBGSqVdY1nHzvKlb0NJERKy8g?width=2550&height=3840&cropmode=none");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    color: white;
    margin: 10px auto 30px auto;
    padding-bottom: 15px;
}
.contactSocial {
    margin-bottom: 20px;
}