
.bookDiv{
    box-shadow: 0.2px 0.2px 2px;
    width: 75%;
    margin: 10px auto;
}
/* Left Column */
@media only screen and (min-width: 1443px){
    .leftCol{
        display: inline-block;
        width: 26.3%;
        margin: 15px 40px 10px 10px;
        vertical-align: top;
    }
    .bookImg {
        width: 90%;
    }
}
@media screen and (min-width: 1279px) and (max-width: 1442px) {
    .leftCol{
        display: inline-block;
        width: 30%;
        margin: 15px 10px 10px 10px;
        vertical-align: top;
    }
    .bookImg {
        width: 90%;
    }
}
@media only screen and (max-width: 1278px) {
    .leftCol{
        display: inline-block;
        width: 100%;
        margin: 15px auto 10px auto;
        vertical-align: top;
    }
    .bookImg {
        width: 50%;
    }
}
/* Center Column */
@media only screen and (min-width: 1443px){
    .centerCol{
        display: inline-block;
        box-shadow: 0.2px 0.2px 2px;
        margin: 15px 40px 10px 10px;
        padding: 0 10px;
        width: 40%;
        vertical-align: top;
    }
}
@media screen and (min-width: 1279px) and (max-width: 1442px) {
    .centerCol{
        display: inline-block;
        width: 35%;
        margin: 15px 10px 10px 10px;
        vertical-align: top;
    }
}
@media only screen and (max-width: 1278px) {
    .centerCol{
        display: inline-block;
        width: 85%;
        margin: 15px auto 10px auto;
        vertical-align: top;
    }
}
.bookContent{
    text-align: justify;
}
.starRating{
    display: inline;
    color: gold;
}
.bookRating{
    display: inline;
    margin: 0 10px;
}
.ratingDiv{
    padding: 25px;
}
/* Right Column */
.rightCol{
    display: inline-block;
    margin: 15px 50px 10px 10px;
    width: 15%;
    vertical-align: top;
}
@media only screen and (min-width: 1443px){
    .rightCol{
        display: inline-block;
        box-shadow: 0.2px 0.2px 2px;
        margin: 15px 50px 10px 10px;
        padding: 0 10px;
        width: 15%;
        vertical-align: top;
    }
}
@media screen and (min-width: 1279px) and (max-width: 1442px) {
    .rightCol{
        display: inline-block;
        width: 17%;
        margin: 15px 40px 10px 10px;
        vertical-align: top;
    }
}
@media only screen and (max-width: 1278px) {
    .rightCol{
        display: inline-block;
        width: 85%;
        margin: 15px auto 10px auto;
        vertical-align: top;
    }
}
.featuredBtn{
    font-size: xx-large;
    border: none;
    background-color:rgba(0, 0, 0, 0);
    color: gold;
}
.bookIsbn, .audiobook, .bookPagesHead, .bookLinkHeading{
    margin: 0;
}
.audiobookLength, .bookPages{
    margin: 0 0 10px 0;
}
.bookLink{
    display: block;
    margin: 5px auto;
    width: 50%;
    padding: 2px 0;
    text-decoration: none;
    border: 1px solid rgb(159, 159, 160);
    border-radius: 3px;
    background-color: rgb(159, 159, 160);
    color: white;
}
/* User Reviews */
.userReviewsDiv{
    padding: 0 0 2.5px 0;
}