.emailFormDiv{
    width: 75%;
    margin: 20px auto;
    padding: 5px 0;
}
.contactEmailIcon{
    font-size: x-large;
    color: rgb(159, 159, 160);
}
.contactEmailButton{
    border: none;
    background-color: rgba(0, 0, 0, 0);
}
.contactEmailAddendum{
    font-size: x-small;
}
.socialBtn{
    font-size: xx-large;
    margin: 0 5px;
}
.socialFB{
    color: #3b5998;
}
.socialIg{
    color:rgb(221, 42, 123);
}
.socialTw{
    color: #1DA1F2;
}
.socialYt{
    color: #FF0000;
}