
.blogDiv{
    box-shadow: 0.2px 0.2px 2px;
    display: inline-block;
    width: 75%;
    margin: 10px auto;
}
.blogCommentsDiv{
    padding: 0 0 2.5px 0;
}
.addCommentBtn{
    padding: 0 0 5px 0;
}
.leftBlogContentDiv{
    width: 45%;
    padding: 10px;
    margin-left: 15px;
    margin-right: 15px;
    display: inline-block;
    vertical-align: top;
}
.rightBlogContentDiv{
    width: 44%;
    display: inline-block;
    margin-right: 50px;
    vertical-align: top;
}
@media only screen and (max-width: 1228px) {
    .infoDiv{
        width: 100%;
    }
}