.loginForm{
    width: 50%;
    margin: 20px auto;
    padding: 5px 0;
}
.forgotBtn{
    text-decoration: none;
    padding: 5px;
    border: 1px solid  rgb(159, 159, 160);
    background-color:  rgb(159, 159, 160);
    border-radius: 3px;
    color: white;
}