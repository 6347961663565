.container{
    width: 100%;
}
@media only screen and (min-width: 1111px){
    .logoDiv{
        display: inline-block;
        width: 25%;
        text-align: left;
    }
    .logoImg{
        width: 35%;
        vertical-align: bottom;
    }
    .navContainer{
        display: inline-block;
        width: 72.5%;
        text-align: right;
    }
    .rotated{
        display: inline;
        margin: 0 2.5px;
    }
    .hamburgerBtnDiv{
        display: none;
    }
    .signOutBtn{
        text-decoration: none;
        border: 1px solid rgb(154, 42, 42);
        background-color: rgb(154, 42, 42);
        color: white;
        border-radius: 5px;
        padding: 0 2.5px;
        margin-left: 5px;
    }
}
@media only screen and (max-width: 1110px){
    .logoDiv{
        display: block;
        width: 100%;
        text-align: center;
    }
    .logoImg{
        width: 25%;
        vertical-align: bottom;
    }
    .navContainer{
        width: 100%;
        text-align: center;
    }
    .rotated{
        display: block;
        margin: 15px 2.5px;
    }
    .hamburgerBtnDiv{
        width: 100%;
    }
    .hamburgerBtn{
        border-style: none;
        background-color: rgba(0, 0, 0, 0);
    }
    .hamburgerBtnIcon{
        font-size: xx-large;
    }
    .signOutBtn{
        text-decoration: none;
        border: 1px solid rgb(159, 159, 160);
        background-color: rgb(159, 159, 160);
        color: white;
        border-radius: 5px;
        margin: 0 15px;
        padding: 0 2.5px;
    }
    
}


.navButton{
    text-decoration: none;
    border: 1px solid rgb(159, 159, 160);
    border-radius: 5px;
    margin: 5px auto;
    padding: 0 5px;
}
.selected{
    background-color: rgb(159, 159, 160);
    color: white;
}
.unselected{
    background-color: rgba(0, 0, 0, 0);
    color: black;
}
.usernameIncluded, .un{
    display: inline;
    margin: 0 2.5px;
}
.deactiveLink{
    pointer-events: none;
}
.registerBtn{
    text-decoration: none;
    border: 1px solid rgb(0, 128, 128);
    background-color: rgb(0, 128, 128);
    color: white;
    border-radius: 5px;
    padding: 0 2.5px;
}
.loginBtn{
    text-decoration: none;
    border: 1px solid rgb(0, 128, 128);
    background-color: rgb(0, 128, 128);
    color: white;
    border-radius: 5px;
    padding: 0 2.5px;
}
.userPageBtn{
    text-decoration: none;
    border: 1px solid rgb(0, 128, 128);
    background-color: rgb(0, 128, 128);
    color: white;
    border-radius: 5px;
    padding: 0 2.5px;
}
