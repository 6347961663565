@media only screen and (min-width: 1001px){
    .raceDiv{
        width: 95%;
        margin: 10px auto;
    }
    .raceInfoDiv{
        display: inline-block;
        width: 85%;
    }
    .rightMoreCol{
        display: inline-block;
        width: 15%;
        vertical-align: bottom;
    }
    .raceImg{
        width: 100%;
    }
    .mgmtBtn{
        display: block;
        padding: 5px 10px;
    }
}
@media only screen and (max-width: 1000px){
    .raceDiv{
        display: block;
        width: 100%;
        margin: 10px;
    }
    .raceInfoDiv{
        display: inline-block;
        width: 100%;
    }
    .rightMoreCol{
        display: inline-block;
        width: 100%;
    }
    .raceImg{
        width: 50%;
    }
    .mgmtBtn{
        padding: 5px;
    }
}
.infoMgmtDiv{
    width: 100%;
}
.raceImgDiv{
    width: 100%;
    margin-bottom: 15px;
}
.raceDetails, .raceName, .raceMagic{
    text-align: justify;
}