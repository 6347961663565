.emailListSignup{
    margin: 0 auto;
}
.emailListLabel{
    margin: 30px auto;
}
input[name="LNAME"], input[name="FNAME"], input[name="EMAIL"], button[type=submit]{
    margin: 10px auto;
    display: block;
}
input[name="LNAME"], input[name="FNAME"], input[name="EMAIL"]{
    width: 50%;
    padding: 5px;
    text-align: center;
}

