body{
  font-family: SourceSansPro;
}
h1, h2, h3, h4, h5, h6 {
  font-family: SourceSerifPro;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.hidden{
  display: none;
}
.newContentBtn{
  padding: 1px 3px;
  border: 1px solid rgb(159, 159, 160);
  border-radius: 3px;
  text-decoration: none;
  background-color: rgb(159, 159, 160);
  color: white;
}
.updateBtn{
  padding: 1px 3px;
  border: 1px solid rgb(159, 159, 160);
  border-radius: 3px;
  text-decoration: none;
  background-color: rgb(159, 159, 160);
  color: white;
}
.deleteBtn{
  padding: 1px 3px;
  border: 1px solid rgb(159, 159, 160);
  border-radius: 3px;
  text-decoration: none;
  background-color: rgb(159, 159, 160);
  color: white;
}
.showHideBtn{
  padding: 1px 3px;
  border: 1px solid  rgb(159, 159, 160);
  border-radius: 3px;
  text-decoration: none;
  background-color:  rgb(159, 159, 160);
  color: white;
}
.submitBtn, button[type=submit]{
  padding: 5px;
  border: 1px solid  rgb(159, 159, 160);
  background-color:  rgb(159, 159, 160);
  border-radius: 3px;
  color: white;
}
.formInputs{
  width: 78%;
  margin: 2.5px auto;
  padding: 3px 0;
  text-align: center;
}
.formTextArea{
  width: 78%;
  margin: 2.5px auto;
  text-align: justify;
}
.formRating{
  width: 12%;
  padding: 5px;
  margin: 2.5px 0;
}
.imgNote{
  font-size: x-small;
  margin: 0 auto;
}
@font-face {
  font-family: SourceSansPro;
  src: url("../fonts/Source_Sans_Pro/SourceSansPro-Regular.ttf");
}
@font-face {
  font-family: SourceSerifPro;
  src: url(../fonts/Source_Serif_Pro/SourceSerifPro-Black.ttf);
}