.bookReviewCommentContainer{
    display: inline-block;
    padding: 0 5px;
    text-align: justify;
}
.bookReviewCommentButtonsDiv{
    display: block;
    float:right;
    margin: 15px 0;
}
.bookReviewCommentUser, .bookReviewCommentDate{
    margin: 5px;
    display: block;
}
.bookReviewCommentUser{
    font-size: small;
    font-weight: 500;
    float: inline-start;
}
.bookReviewCommentDate{
    font-size: xx-small;
    float: left;
}
