.adminUsernameDiv{
    display: inline-flex;
    width: 100%;
}
.adminUsername{
    display: inline-block;
    margin: 0 15px;
}
.adminShowHideBtnDiv{
    display: inline-block;
}
.adminEmail, .adminFirstName, .adminLastName, .adminSecurityQuestion, .adminStatus{
    display: inline;
    margin: 2.5px 10px;
}
.adminBtn{
    border-style: none;
    background-color: rgba(0, 0, 0, 0);
    font-size: x-large;
}
.adminDiv{
    margin: 15px 0;
}
.adminStatus{
    font-size: large;
}
.adminDeleteDiv{
    display: block;
    margin: 10px 10px;
    float: right;
}
.fillerDiv{
    display: inline;
    width: 100%;
}
.adminDeleteBtn{
    margin: 10px 0;
}
.userUpdateFormInput{
    margin: 0 7.5px;
}
.userEditShowHide{
    margin: 0 0 0 7.5px;
    padding: 5px;
    border: 1px solid  rgb(159, 159, 160);
    background-color:  rgb(159, 159, 160);
    border-radius: 3px;
    color: white;
}
.userUserDiv{
    text-align: justify;
    padding: 15px;
}