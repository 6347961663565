.blogCommentContainer{
    display: inline-block;
    padding: 0 5px;
    text-align: justify;
}

@media only screen and (min-width: 900px) {
    .rightComment, .leftComment{
        margin: 2.5px 0;
        display: block;
        width: 45%;
    }
    .rightComment{
        float: right;
        margin-right: 5px;
        background-color: rgb(248, 240, 255);
        color: black;
    }
    .leftComment{
        float: left;
        margin-left: 5px;
        background-color: rgb(240, 240, 240);
        color: black;
    }
}
@media only screen and (max-width: 899px){
    .rightComment, .leftComment{
        margin: 2.5px 0;
        display: block;
        width: 90%;
    }
    .rightComment{
        float: right;
        margin-right: 5px;
        background-color: rgb(248, 240, 255);
        color: white;
    }
    .leftComment{
        float: left;
        margin-left: 5px;
        background-color: rgb(240, 240, 240);
        color: black;
    }
}
.blogCommentButtonsDiv{
    display: block;
    float:right;
    margin: 15px 0;
}
.blogCommentUser, .blogCommentDate{
    margin: 5px;
    display: block;
}
.blogCommentUser{
    font-size: small;
    font-weight: 500;
    float: inline-start;
}
.blogCommentDate{
    font-size: xx-small;
    float: left;
}




.leftEmptyCol, .rightEmptyCol{
    display: inline-block;
    width: 100%;
}
.leftEmptyCol{
    float: left;
}
.rightEmptyCol{
    float: right;
}