.morePageDiv{
    width: 75%;
    margin: 10px auto;
}
.sectionHeader{
    text-align: justify;
    margin-right: 10px ;
}
.sectionAddBtn{
    width: 100%;
    display: inline-block;
    text-align: right;
    margin: 0;
    pad: 0;
}